<template>
  <div class="pbf grid_width">
 
    <GeneralSearchTags  
      :class="{gloading: is_loading}"     
      :tags="props.intags" 
      :tagsselected="tagsselected" 
      :tagsdefault="props.intags" 
      :tagscount="explore_data?.items_tags"      
      @choose="chooseTags"
      @unchoose="unchooseTags"
      />

    <div class="sortb">
      <UiSort 
        @choose="chooseSort"
        :val="sort"    
        :options="[
          {id:'update', name: $t('universal_sort_last_update')},      
          {id:'score', name: $t('universal_sort_grow_score')},      
          {id:'likes', name: $t('universal_sort_likes2')},      
          {id:'create', name: $t('universal_sort_creation_date')},      
        ]"
        />
    </div>

      
    <SkExploreDiaries
      v-if="is_loading && !explore_data?.items?.length"
      />
      
    <div v-else-if="explore_data?.items?.length" class="report_boxs reports_grid">      
      <GeneralDiary       
        v-for="(opt,ind) in explore_data.items"    
        :key="ind"    
        :data="opt"      
        />          
      <client-only>        
        <InfinityScroll 
          v-if="explore_data?.items?.length >= limit"
          :is-loading="is_loading"
          ref="el_infinity_scroll"/>
      </client-only>
    </div>

    <Empty
      v-else-if="!explore_data?.items?.length"
      />

    <SkExploreDiaries
      v-else
      />
  

  </div>
</template>

<script setup>
 

import { useIntersectionObserver } from '@vueuse/core'
import { watchArray } from '@vueuse/core'


const start = ref(0)
const limit = ref(20)
const is_ended = ref(false)
const {$api, $adv, $tagsUtil, $ga} = useNuxtApp()
const el_infinity_scroll = ref(null)
const sort = ref('update')
const tagsselected = ref([])
const tagspermanent = ref([]);

const props = defineProps({
  inurl: {
    type: String
  },
  intags: {
    type: Object
  },
  owner: {
    type: Boolean,
    default: false
  },
  brandId: {
    type: Number,
    required: false
  },
  productId: {
    type: Number,
    required: false
  },
  growerId: {
    type: Number,
    required: false
  }
});


if(props.brandId){
  tagspermanent.value.push('br:' + props.brandId);
}

if(props.productId){
  tagspermanent.value.push('pr:' + props.productId);
}

if(props.growerId){
  tagspermanent.value.push('us:' + props.growerId);
}


const clearPagination = function(){     
  start.value = 0;  
}

const chooseSort = function(selected_sort){   
  sort.value = selected_sort;    
  clearPagination();  
}

    
// const chooseTagKeyword = function(tag){     
//   intags.value[tag.tag_id] = tag;
//   tagsselected.value.push(tag.tag_id)  
//   clearPagination();        
//   $ga.searchFullTextExplore($tagsUtil.getTagsKeyword(intags.value, tagsselected.value));
// } 

 

const unchooseTags = function(selected){  
  tagsselected.value = tagsselected.value.filter((item) => item != selected);
}        
const chooseTags = function(selected){          
  tagsselected.value.push(selected);      
  clearPagination();    
  $ga.eventGA('explore', 'tag', $tagsUtil.getTagsSimple(props.intags, tagsselected.value, tagspermanent.value));
}


const loadData = async function() {  
  const response = await $api.getDiaries({
    start: start.value,
    limit: limit.value,
    owner: props.owner ? 1 : 0,
    q: $tagsUtil.getTagsKeyword(props.intags, tagsselected.value, tagspermanent.value),       
    sortable: sort.value,
    tagsall: $tagsUtil.getTagsKeys(props.intags),
    tags: $tagsUtil.getTagsSimple(props.intags, tagsselected.value, tagspermanent.value)
  });
  return response;  
}



const { pending: is_loading,  data: explore_data } = await useLazyAsyncData('explore_data', async () => await loadData())


watchArray([start, sort, tagsselected], async ([new_start, new_sort, new_tagsselected], added, removed) => {
  
  if(new_start == 0){
    explore_data.value.items = [];
    is_ended.value = false;
  }
  
  is_loading.value = true;
  var dt = await loadData();

    
  if(dt.length == 0)
    is_ended.value = true;  

  explore_data.value.items = [...explore_data.value.items, ...dt.items]
  explore_data.value.items_tags = dt.items_tags
  is_loading.value = false;

  // $adv.apply();

}, {deep: true})



useIntersectionObserver(
  el_infinity_scroll,
  ([{isIntersecting}]) => {
    if(isIntersecting){
      console.log('infinity scroll event');        
      if(!is_loading.value && !is_ended.value)
        start.value = start.value + limit.value;
    }
  },
  { distance: 10 }
)




// export default { 
//   components: {        
  
//   },  
//   props:{
//     owner:{
//       type: Boolean,
//       default: false
//     },
//     grower:{
//       type: String
//     },
//     inurl:{
//       type: String
//     },
//     intags:{
//       type: Object
//     }
//   },
//   mounted() {     
//       this.loadResults();   
//   },
//   data () {
//     return {          
//       url: this.inurl,
//       tags: this.intags, 
//       start: 0, 
//       limit: 20,   
//       query: '',
//       sort: 'update',            
//       tagscount: [],
//       tagsselected: ['al'],
//       tagsdefault: 'al',
//       diaries: []
//     }
//   },  
//   methods: {
//     clearPage(){   
//       this.start = 0;
//       this.diaries = [];
//     },         
//     chooseTags(){            
//       this.clearPage();      
//       this.loadResults();
//     },
//     chooseSort(data){   
//       this.sort = data;    
//       this.clearPage();           
//       this.loadResults();
//     },
//     infiniteHandler(state){
//       this.start += this.limit;
//       this.loadResults(state);
//     },
//     async loadResults(state){
 
//       var dt = await this.$api.getUrl(this.url, {
//         start: this.start,
//         limit: this.limit,
//         q: this.query, 
//         sortable: this.sort,
//         tags: this.tagsselected.join(',')
//       });

//       if(state && !dt.items.length) state.complete();
//       if(state && dt.items.length) state.loaded();    
      
      
//       this.tagscount = dt.items_tags;    
//       this.diaries.push(...dt.items);
 
//      }
//   }
// }
</script>

<style scoped>


.add_filter_box{
  display: flex;
  margin-left: auto;
  align-self: baseline;
}

.sortb{
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: relative;
}

</style>
